@use '@/styles/utils/mixins.scss' as *;

.dealershipBannerCTA {
  background-color: $kiaMidnightBlack;
  width: 100%;
}
.bannerSectionWrapper {
  position: relative;
  background-color: $kiaMidnightBlack;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    min-height: calc(100vh - 83px);
  }

  .bannerContentSection {
    width: 100%;
    position: relative;
    z-index: 1;
    @media screen and (min-width: 992px) {
      min-height: calc(100vh - 350px);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: linear-gradient(
        180deg,
        rgba(5, 20, 31, 0) 80%,
        $kiaMidnightBlack
      );

      @media screen and (max-width: 576px) {
        display: none;
      }
    }
    .headingElement {
      position: relative;
      z-index: 1;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'KiaSignature';
        font-weight: 600;
        font-style: normal;
        &.titleXl {
          font-size: 60px;
          line-height: 60px;
          @include tab() {
            font-size: 48px;
            line-height: 60px;
          }
          @include mob() {
            font-size: 36px;
            line-height: 43.2px;
          }
        }
        &.titleLg {
          font-size: 48px;
          line-height: 60px;
          @include tab() {
            font-size: 36px;
            line-height: 43.2px;
          }
          @include mob() {
            font-size: 24px;
            line-height: 28.8px;
          }
        }
        &.titleXs {
          font-size: 16px;
          line-height: 19.2px;
        }
      }
    }
    .bannerCta {
      padding: 11px 15px 9px;
      line-height: 16px;
    }
  }
  &.bannerSectionNoCTA {
    min-height: auto;

    .bannerContentSection {
      @media screen and (min-width: 992px) {
        padding-bottom: 54px;
      }
    }
  }
  .bannerSectionImage {
    z-index: -1;
    @media screen and (max-width: 991.99px) {
      position: static !important;
      height: auto !important;
      min-height: 300px;
    }
  }
}
